import Vue from "vue";
import Router from "vue-router";
import axios from "axios";
import store from "./store";

Vue.use(Router);
let router = new Router({
  mode: "history",
  routes: [
    // {
    //   path: "/",
    //   props: true,
    //   component: () => import("./layout/Default"),
    //   meta: {
    //     requiresAuth: true,
    //   },
    //   children: [
    //     {
    //       path: "",
    //       name: "dashboard",
    //       meta: {
    //         requiresAuth: true,
    //       },
    //       component: () => import("./views/Dashboard/Dashboard"),
    //     },
    //     {
    //       path: "/add",
    //       name: "adds",
    //       meta: {
    //         requiresAuth: true,
    //       },
    //       component: () => import("./layout/Admins/addMenu"),
    //     },
    
    //  ],
    // },

    
        {
          path: "/",
          name: "login",
          meta: {
            requiresAuth: false,
          },
          component: () => import("./views/Authentication/Login"),
        },
        {
          path: "/testpage",
          name: "testpage",
          meta: {
            requiresAuth: false,
          },
          component: () => import("./views/testpage"),
        },
        {
          path: "signup",
          name: "signup",
          meta: {
            requiresAuth: false,
          },
          component: () => import("./views/Authentication/Signup"),
        },
        {
          path: "/add",
          name: "adds",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./layout/Admins/addMenu"),
        },
        
        {
          path: "/dashboard",
          props: true,
          component: () => import("./layout/BaseLayout"),
          meta: {
            requiresAuth: true,
            isSuperAdmin: true,
            isJewellaryAdmin: true,
            isStaff: true,

          },
          children: [
            //SuperAdmin
            {
              path: "/superadminDashboard",
              name: "superadminDashboard",
              meta: {
                requiresAuth: true,
                isSuperAdmin: true,
                isJewellaryAdmin: false,
                isStaff: false,
              },
              component: () => import("./views/SuperAdmin/superadminDashboard"),
            },
            {
              path: "/ViewAdmin",
              name: "ViewAdmin",
              meta: {
                requiresAuth: true,
                isSuperAdmin: true,
                isJewellaryAdmin: false,
                isStaff: false,

              },
              component: () => import("./views/SuperAdmin/ViewAdmin"),
            },
            //add new menu&submenu
            {
              path: "/MenuMaster",
              name: "MenuMaster",
              meta: {
                requiresAuth: true,
                isSuperAdmin: true,
                isJewellaryAdmin: false,
                isStaff: false,

              },
              component: () => import("./views/SuperAdmin/MenuMaster"),
            }, 
            //jewellary admin
            {
              path: "/JewelleryDashboard",
              name: "JewelleryDashboard",
              meta: {
                requiresAuth: true,
                isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: false,

              },
              component: () => import("./views/JewelAdmin/JewelleryDashboard"),
            },
            {
              path: "/userProfile",
              name: "userProfile",
              meta: {
                requiresAuth: true,
                 isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/JewelAdmin/userProfile"),
            },
            {
              path: "/BoardingRate",
              name: "BoardingRate",
              meta: {
                requiresAuth: true,
                 isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: false,
              },
              component: () => import("./views/JewelAdmin/BoardingRate"),
            },
            {
              path: "/rolesList",
              name: "rolesList",
              meta: {
                requiresAuth: true,
                 isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/JewelAdmin/rolesList"),
            },
            {
              path: "/Staff",
              name: "StaffManagement",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: false,
              },
              component: () => import("./views/JewelAdmin/StaffManagement"),
            },
            {
              path: "/Supplier",
              name: "SupplierManagement",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/JewelAdmin/Supplier/SupplierManagement"),
            },
            {
              path: "/SupplierDetail",
              name: "SupplierDetail",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/JewelAdmin/Supplier/SupplierDetail"),
            },
            {
              path: "/supplierSettlement",
              name: "supplierSettlement",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/JewelAdmin/Supplier/supplierSettlement"),
            },
            {
              path: "/settledList",
              name: "settledList",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/JewelAdmin/settledList"),
            },
            {
              path: "/purchaseDetails",
              name: "purchaseItemDetails",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/JewelAdmin/Purchase/purchaseItemDetails"),
            },
            {
              path: "/Customer",
              name: "CustomerManagement",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/JewelAdmin/CustomerManagement"),
            },
            // {
            //   path: "/testpage2",
            //   name: "Category2",
            //   meta: {
            //     requiresAuth: true,
            //       isSuperAdmin: false,
            //     isJewellaryAdmin: true,
            //     isStaff: true,
            //   },
            //   component: () => import("./views/JewelAdmin/Categorytest1"),
            // },
            {
              path: "/Category",
              name: "testpage3",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/JewelAdmin/testpage3"),
            },
            // {
            //   path: "/CategoryManagementOld2",
            //   name: "Category2",
            //   meta: {
            //     requiresAuth: true,
            //       isSuperAdmin: false,
            //     isJewellaryAdmin: true,
            //     isStaff: true,
            //   },
            //   component: () => import("./views/JewelAdmin/Categorytest2"),
            // },
            // {
            //   path: "/CategoryManagementOld3",
            //   name: "Category1",
            //   meta: {
            //     requiresAuth: true,
            //       isSuperAdmin: false,
            //     isJewellaryAdmin: true,
            //     isStaff: true,
            //   },
            //   component: () => import("./views/JewelAdmin/testcat"),
            // },
            // {
            //   path: "/CategoryManagementOld",
            //   name: "CategoryManagement",
            //   meta: {
            //     requiresAuth: true,
            //       isSuperAdmin: false,
            //     isJewellaryAdmin: true,
            //     isStaff: true,
            //   },
            //   component: () => import("./views/JewelAdmin/CategoryManagement"),
            // },
            // {
            //   path: "/CategoryItem",
            //   name: "CategoryItem",
            //   meta: {
            //     requiresAuth: true,
            //       isSuperAdmin: false,
            //     isJewellaryAdmin: true,
            //     isStaff: true,
            //   },
            //   component: () => import("./views/JewelAdmin/CategoryItem"),
            // },
            // {
            //   path: "/Design",
            //   name: "ItemDesignManagement",
            //   meta: {
            //     requiresAuth: true,
            //       isSuperAdmin: false,
            //     isJewellaryAdmin: true,
            //     isStaff: true,
            //   },
            //   component: () => import("./views/JewelAdmin/ItemDesignManagement"),
            // },
            {
              path: "/hsnCode",
              name: "hsnCode",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/JewelAdmin/hsnCode"),
            },
            {
              path: "/Purchase",
              name: "Purchase",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/JewelAdmin/Purchase/Purchase"),
            },
            {
              path: "/PurchaseSettlement2",
              name: "PurchaseSettlement2",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/JewelAdmin/Purchase/PurchaseSettlement2"),
            },
            {
              path: "/PurchaseSettlement",
              name: "PurchaseSettlement",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/JewelAdmin/Purchase/PurchaseSettlement"),
            },
            {
              path: "/PurchaseList",
              name: "PurchaseList",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/JewelAdmin/Purchase/PurchaseList"),
            },
           
            {
              path: "/MenuManagement",
              name: "MenuManagement",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: false,
              },
              component: () => import("./views/JewelAdmin/MenuManagement"),
            },
            {
              path: "/StockPage",
              name: "StockPage",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/JewelAdmin/StockPage"),
            },
            {
              path: "/GoldSchemeold",
              name: "GoldScheme",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/StaffAdmin/GoldScheme"),
            },
            {
              path: "/GoldSchemeView",
              name: "GoldSchemeView",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                 isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/StaffAdmin/GoldSchemeView"),
            },
            {
              path: "/GoldSchemeCreation",
              name: "GoldSchemeCreation",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                 isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/StaffAdmin/GoldSchemeCreation"),
            },
            {
              path: "/GoldSchemeEnlisting",
              name: "GoldSchemeEnlisting",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                 isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/StaffAdmin/GoldSchemeEnlisting"),
            },
            {
              path: "/GoldSchemelist",
              name: "GoldSchemelist",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                 isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/StaffAdmin/GoldSchemelist"),
            },
            {
              path: "/ProfitandLoss",
              name: "ProfitandLoss",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                 isJewellaryAdmin: true,
                isStaff: false,
              },
              component: () => import("./views/StaffAdmin/ProfitandLoss"),
            },
            {
              path: "/AddAciding",
              name: "AddAciding",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                 isJewellaryAdmin: true,
                isStaff: false,
              },
              component: () => import("./views/StaffAdmin/Acidifying/AddAciding"),
            },
            {
              path: "/AcidingList",
              name: "BeforeAcidingList",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                 isJewellaryAdmin: true,
                isStaff: false,
              },
              component: () => import("./views/StaffAdmin/Acidifying/BeforeAcidingList"),
            },
            {
              path: "/ReturnAciding",
              name: "ReturnAciding",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                 isJewellaryAdmin: true,
                isStaff: false,
              },
              component: () => import("./views/StaffAdmin/Acidifying/ReturnAciding"),
            },
            {
              path: "/AcidingReport",
              name: "AcidingReport",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                 isJewellaryAdmin: true,
                isStaff: false,
              },
              component: () => import("./views/StaffAdmin/Acidifying/AcidingReport"),
            },
            {
              path: "/AcidingPage",
              name: "AcidingPage",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                 isJewellaryAdmin: true,
                isStaff: false,
              },
              component: () => import("./views/StaffAdmin/AcidingPage"),
            },
            {
              path: "/AcidingConfirm",
              name: "AcidingConfirm",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                 isJewellaryAdmin: true,
                isStaff: false,
              },
              component: () => import("./views/StaffAdmin/AcidingConfirm"),
            },
            {
              path: "/AcidingAfter",
              name: "AcidingAfter",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                 isJewellaryAdmin: true,
                isStaff: false,
              },
              component: () => import("./views/StaffAdmin/AcidingAfter"),
            },
            {
              path: "/oldGoldList",
              name: "oldGoldList",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                 isJewellaryAdmin: true,
                isStaff: false,
              },
              component: () => import("./views/JewelAdmin/OldGold/oldGoldList"),
            },
            {
              path: "/returnGoldList",
              name: "returnGoldList",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                 isJewellaryAdmin: true,
                isStaff: false,
              },
              component: () => import("./views/JewelAdmin/ReturnGold/returnGoldList"),
            },
            
            //StaffDashboard
            {
              path: "/test",
              name: "test",
              meta: {
                requiresAuth: true,
                isSuperAdmin: false,
                isJewellaryAdmin: false,
                isStaff: true,
              },
              component: () => import("./views/StaffAdmin/test"),
            },
            {
              path: "/StaffDashboard",
              name: "StaffDashboard",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: false,
                isStaff: true,
              },
              component: () => import("./views/StaffAdmin/StaffDashboard"),
            },
            {
              path: "/billDetail",
              name: "billDetail",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/StaffAdmin/Sales/billDetail"),
            },
            {
              path: "/sales",
              name: "sales",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/StaffAdmin/sales"),
            },
            {
              path: "/SalesList",
              name: "SalesList",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/StaffAdmin/Sales/SalesList"),
            },
            {
              path: "/SalesPage1",
              name: "SalesPage1",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/StaffAdmin/Sales/SalesPage1"),
            },
            {
              path: "/SalesSettlement",
              name: "SalesSettlement",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/StaffAdmin/Sales/SalesSettlement"),
            },
            {
              path: "/SalesSettlementEdit",
              name: "SalesSettlementEdit",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/StaffAdmin/Sales/SalesSettlementEdit"),
            },
            {
              path: "/salesReport",
              name: "salesReport",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/StaffAdmin/Sales/salesReport"),
            },
            {
              path: "/EstimatePage",
              name: "EstimatePage",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/StaffAdmin/Estimate/EstimatePage"),
            },
            {
              path: "/EstimatePage2",
              name: "EstimatePage2",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/StaffAdmin/EstimatePage2"),
            },
            {
              path: "/SalesPage2",
              name: "SalesPage2",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/StaffAdmin/SalesPage2"),
            },
            {
              path: "/GoldScheme",
              name: "GoldSchemeList",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/StaffAdmin/GoldScheme/GoldSchemeList"),
            },
            {
              path: "/CustomerSchemeList",
              name: "CustomerSchemeList",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/StaffAdmin/GoldScheme/CustomerSchemeList"),
            },
            // 
            {
              path: "/CustomerDetail",
              name: "CustomerDetail",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/StaffAdmin/GoldScheme/CustomerDetail"),
            },
            {
              path: "/GoldSchemePayment",
              name: "GoldSchemePayment",
              meta: {
                requiresAuth: true,
                  isSuperAdmin: false,
                isJewellaryAdmin: true,
                isStaff: true,
              },
              component: () => import("./views/StaffAdmin/GoldScheme/GoldSchemePayment"),
            },
          ],
        },
        
        {
          path: "/paymentTest",
          name: "paymentTest",
          props: true,
          component: () => import("./backup/paymentTest"),
          meta: {
            requiresAuth: true,
          },
        },
    {
      path: "/servererror",
      name: "500",
      props: true,
      component: () => import("./common/500"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/500",
      name: "servererror",
      props: true,
      component: () => import("./common/500old"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "*",
      name: "404pagenotfound",
      props: true,
      component: () => import("./common/404"),
      meta: {
        requiresAuth: false,
      },
    },
  ],
  scrollBehavior() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  },
});
router.beforeEach((to, from, next) => {
  //new lines
  localStorage.setItem("PRE", from.path);
  if (store.state.isLoggedIn == true || to.matched.some(route => route.meta.requiresAuth == true)) {
    axios({
            method: 'POST',
            url: '/validate/token',
            headers: {
                'x-auth-token': localStorage.getItem('token')
            }
        }).then(response => {
            if (!response.data.status) {
              console.log("router logiut")
                          localStorage.clear();
                          router.push("/");
                          // this.$store.commit("logoutUser", true);
                return
            }
        })
        .catch(err => {
            var msg = err;
            console.log(msg)
        });
}
  // end  of newely added lines
  if (
    to.matched.some((route) => route.meta.requiresAuth == true) &&
    store.state.isLoggedIn == false
  ) {
    // console.log(from);
    next({ name: "login", params: { lastPage: from } });
    return;
  } 
  
  // if (store.state.isLoggedIn == true) {
  //       axios({
  //         method: "POST",
  //         url: "/validate/token",
  //         headers: {
  //           "x-auth-token": localStorage.getItem("token"),
  //         },
  //       })
  //         .then((response) => {
  //           if (response.data.status==false) {
  //             console.log("router logiut")
  //             // store.commit("sessionOut", true);
  //             localStorage.removeItem("id");
  //             localStorage.removeItem("token")
  //             localStorage.clear();
  //             router.push("/");
  //             this.$store.commit("logoutUser", true);
  //             return;
  //           }
  //         })
  //         .catch((err) => {
  //           var msg = err;
  //           console.log(msg);
  //         });
  //     }
  if (
    to.name == "login" &&
    store.state.isLoggedIn == true &&
    localStorage.getItem("userType") == "SuperAdmin"
  ) {
    next({ name: "superadminDashboard" });
    return;
  }

  if (
    to.name == "login" &&
    store.state.isLoggedIn == true &&
    localStorage.getItem("role") === "JewelleryAdmin"
  ) {
    next({ name: "JewelleryDashboard" });
    return;
  }
  if (
    to.name == "login" &&
    store.state.isLoggedIn == true &&
    localStorage.getItem("role") === "Staff"
  ) {
    next({ name: "StaffDashboard" });
    return;
  }
// Redirection userwise
if (
  to.matched.some((route) => route.meta.isSuperAdmin == false) && from.name != "login" &&
  store.state.isLoggedIn == true && store.state.role == 'SuperAdmin'
) {
  next({ name: "superadminDashboard" });
  return;
}
if (
  to.matched.some((route) => route.meta.isJewellaryAdmin == false) && from.name != "login" &&
  store.state.isLoggedIn == true && store.state.userType == 'JewelleryAdmin'
) {
  // console.log("2");
  next({ name: "JewelleryDashboard" });
  return;
}
if (
  to.matched.some((route) => route.meta.isStaff == false) && from.name != "login" &&
  store.state.isLoggedIn == true && store.state.userType == 'Staff'
) {
  next({ name: "StaffDashboard" });
  return;
}


  next();
});

export default router;
