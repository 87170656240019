<template>
  <div>
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <!-- <PageLoader /> -->
    <v-app id="app">
      <span style="min-height: 100vh">
        <router-view  v-bind:storage="sideNav" />
      </span>
    </v-app>
  </div>
</template>
<script>
import axios from "axios";
import store from "./store";

import "./assets/styles/fonts.css";
import "./assets/styles/appStyle.css";
var vm = {
  stat: '',
  changeStat: function() {
    axios({
        url: "/change/taxtype",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: localStorage.getItem("id"),
          taxType: this.stat,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            var stat1 = response.data.data.taxType;
            localStorage.setItem("GSTstatus", stat1);
            store.commit("GSTstatus", stat1);
            console.log("model==", this.stat);
            this.msg = response.data.msg;
            this.showsnackbar = true;
            // this.$route.go();
            location.reload();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
  }
};

document.addEventListener('keydown', function(event) {
  if (event.keyCode == 71 && event.shiftKey && event.ctrlKey) {
    console.log('Left was pressed');
    vm.stat = true;
    vm.changeStat();
  } else if (event.keyCode == 68 && event.shiftKey && event.ctrlKey) {
    console.log('Right was pressed');
    vm.stat = false;
    vm.changeStat();
  }
}, true);






// var vm = this;
// document.addEventListener('keydown', function(event) {
//     if (event.keyCode == 37) {
//         console.log('Left was pressed');
//       vm.stat="GST";
//       vm.changeStat();
//     }
//     else if (event.keyCode == 39) {
//         console.log('Right was pressed');
//         vm.stat="NOGST";
//         vm.changeStat();
//     }
// }, true);
export default {
  data() {
    return {
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      check:false,
      sideNav: false,
      stat:"GST",
      billDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },
  computed: {
    queryPopUpDialog1() {
      return this.$store.state.queryPopUpDialog;
    },
  },
  watch:{
    queryPopUpDialog1(){
this.queryPopUpDialog=this.queryPopUpDialog1;

    }

  },
  beforeMount() {
    // if(this.queryPopUpDialog1){
    //   console.log("queryPopUpDialog1==",this.queryPopUpDialog1)
this.queryPopUpDialog=this.queryPopUpDialog1;
// console.log("dialogval==",this.queryPopUpDialog)
    // }
    if (typeof localStorage.getItem("token") == "string") {
      this.$store.commit("appLoading", true);
      axios({
        method: "GET",
        url: "/user/info",
        headers: {
          "x-auth-token": localStorage.getItem ("token"),
        },
        params:{
          id:localStorage.getItem("id"),
          date: this.billDate,
        }
      })
        .then((response) => {
          // console.log("curr path==",this.$route.path);
          if (response.data.status) {
            // if(this.$route.path=='/estimatepage')
            // {
            //   var check = response.data.goldrateadded;
            // if (check == false) {
            //   console.log("check===-=", check);
            //   this.queryPopUpDialog = true;
            //   store.commit("queryPopUpDialog", true);
            // }
            // }
            this.$store.commit("menu", response.data.menus);
            this.check = response.data.goldrateadded;
            this.staffCode = response.data.data.staffCode;
            if(this.staffCode){
              localStorage.setItem("staffCode",this.staffCode)
            }
            if(response.data.data.subRoleId){
              var juwellaryId = response.data.data.subRoleId.jewelleryId._id;
              localStorage.setItem("juwellaryId", juwellaryId)
            }
            // console.log("check=",this.check)
            this.$store.commit("appLoading", false);
            this.$store.commit("userData", response.data.data);
            this.$store.commit("fullData", response.data);
            this.$store.commit("userType", response.data.data.mainRole);
          }
          else
          {
            this.$store.commit("appLoading", false);

          }
        })
        .catch((err) => {
          this.$store.commit("appLoading", false);
          console.log(err);
        });
    }
    // this.checkBording();
    this.getBording();

  },
  methods: {
   getBording() {
    var token=localStorage.getItem("token")
    if(token){
    // console.log("2");
      this.appLoading = true;
      axios({
        url: "/rate/view",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          date: this.billDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true){
          this.headData = response.data.data;
          store.commit("headData", response.data.data);
          this.diamond = response.data.data.diamond;
          localStorage.setItem("diamond", response.data.data.diamond);
          this.goldRate = response.data.data.goldRate;
          localStorage.setItem("goldRate", response.data.data.goldRate);
          this.platinumRate = response.data.data.platinumRate;
          localStorage.setItem("platinumRate", response.data.data.platinumRate);
          this.silverRate = response.data.data.silverRate;
          localStorage.setItem("silverRate", response.data.data.silverRate);
          this.thankamRate = response.data.data.thankamRate;
          localStorage.setItem("thankamRate", response.data.data.thankamRate);
          }
          else{
            // console.log("true");
            this.msg = response.data.msg;
            this.showsnackbar = true;
            localStorage.removeItem("diamond");
          localStorage.removeItem("goldRate");
          localStorage.removeItem("platinumRate");
          localStorage.removeItem("silverRate");
          localStorage.removeItem("thankamRate");
          }

        })
        .catch((err) => {
          //   this.ServerError = true;
          console.log(err);
        });
      }
    },
    EditRate() {
      axios({
        url: "/rate/add",
        method: "POST",
        data: {
          goldRate: this.gold,
          silverRate: this.silverRate,
          platinumRate: this.platinumRate,
          diamond: this.diamond,
          date: this.date,
          thankamRate: this.thankamRate,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.queryPopUpDialog=false;
            this.getBording();
            // this.goldrate = null;
            // this.silverrate = null;
            // this.platinumrate = null;
            // this.diamondrate = null;
            this.date = null;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
