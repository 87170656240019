import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import VueApexCharts from 'vue-apexcharts'
import ServerError from './common/500'
// import './stylus/main.styl'
import store from './store'

//date time picker
import DatetimePicker from 'vuetify-datetime-picker'
// import './components/stylus/main.styl'
Vue.use(DatetimePicker)


// import JsonExcel from "vue-json-excel";
// Vue.component("downloadExcel",JsonExcel);


Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
import VueElementLoading from 'vue-element-loading'
Vue.component('VueElementLoading', VueElementLoading)
Vue.component('ServerError', ServerError)

Vue.config.productionTip = false
// axios.defaults.baseURL = 'http://192.168.50.62:3200';
// Vue.prototype.baseURL = 'http://192.168.50.62:3200';
// Vue.prototype.mediaURL = 'http://192.168.50.62:3200/file/get/';//re local

// axios.defaults.baseURL = 'http://192.168.54.34:3200';
// Vue.prototype.baseURL = 'http://192.168.54.34:3200'; 
// Vue.prototype.mediaURL = 'http://192.168.54.34:3200/file/get/';

// axios.defaults.baseURL = 'http://192.137.106.6:3200';
// Vue.prototype.baseURL = 'http://192.137.106.6:3200';
// Vue.prototype.mediaURL = 'http://192.137.106.6:3200/file/get/';

axios.defaults.baseURL = 'https://jewelapi.leopardtechlabs.in';
Vue.prototype.baseURL = 'https://jewelapi.leopardtechlabs.in';
Vue.prototype.mediaURL = 'https://jewelapi.leopardtechlabs.in/file/get/';

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')